let combatStatMods = {
    dexAtk: {
        1: -3,
        2: -3,
        3: -3,
        4: -2,
        5: -2,
        6: -1,
        7: -1,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 1,
        14: 1,
        15: 1,
        16: 2,
        17: 2,
        18: 2,
        19: 2,
        20: 2,
        21: 3,
        22: 3,
        23: 3
    },
    dexDef: {
        1: -3,
        2: -2,
        3: -2,
        4: -1,
        5: -1,
        6: -1,
        7: -1,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1,
        19: 2,
        20: 2,
        21: 2,
        22: 2,
        23: 2
    },
    dexInit: {
        1: 3,
        2: 2,
        3: 2,
        4: 1,
        5: 1,
        6: 1,
        7: 1,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: -1,
        14: -1,
        15: -1,
        16: -1,
        17: -1,
        18: -1,
        19: -2,
        20: -2,
        21: -2,
        22: -2,
        23: -2
    },
    intAtk: {
        1: -6,
        2: -5,
        3: -4,
        4: -3,
        5: -2,
        6: -1,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 1,
        12: 1,
        13: 2,
        14: 2,
        15: 2,
        16: 3,
        17: 3,
        18: 4,
        19: 4,
        20: 4,
        21: 5,
        22: 5,
        23: 6
    },
    willDef: {
        1: -6,
        2: -5,
        3: -4,
        4: -3,
        5: -2,
        6: -1,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 1,
        12: 1,
        13: 1,
        14: 2,
        15: 2,
        16: 3,
        17: 3,
        18: 4,
        19: 4,
        20: 5,
        21: 5,
        22: 5,
        23: 5
    },
    willInit: {
        1: 6,
        2: 5,
        3: 4,
        4: 3,
        5: 2,
        6: 1,
        7: 1,
        8: 1,
        9: 0,
        10: 0,
        11: -1,
        12: -1,
        13: -2,
        14: -2,
        15: -2,
        16: -3,
        17: -3,
        18: -4,
        19: -4,
        20: -4,
        21: -5,
        22: -5,
        23: -5
    },
    strDam: {
        1: -5,
        2: -4,
        3: -3,
        4: -2,
        5: -2,
        6: -2,
        7: -2,
        8: -1,
        9: -1,
        10: 0,
        11: 1,
        12: 1,
        13: 2,
        14: 2,
        15: 2,
        16: 3,
        17: 3,
        18: 3,
        19: 4,
        20: 4,
        21: 4,
        22: 4,
        23: 5
    },
    strRec: {
        1: 1.5,
        2: 1,
        3: 1,
        4: 1,
        5: .5,
        6: .5,
        7: .5,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: -.5,
        13: -.5,
        14: -.5,
        15: -.5,
        16: -1,
        17: -1,
        18: -1,
        19: -1,
        20: -1.5,
        21: -1.5,
        22: -1.5,
        23: -2
    }
}

export default combatStatMods